<template>
  <div class="app-container">
    <div class="head-container">
      <el-date-picker size="small" class="filter-item" v-model="query.dateRange" type="daterange" value-format="timestamp" range-separator="至" start-placeholder="入库日期" end-placeholder :default-time="defaultTime" @change="toQuery" style="width: 280px;" />
      <quick-select class="filter-item" v-model="query.storageId" clearable displayField="name" url="api/warehouse" placeholder="入库库房" filterable @change="toQuery" style="width:180px" />
      <el-select v-model="query.formTypeList" placeholder="入库类型" style="width:170px" class="filter-item" clearable @change="toQuery" multiple collapse-tags>
        <el-option v-for="(v,k) in storageTypes" :key="k" :label="v" :value="k"></el-option>
      </el-select>
      <el-select v-model="query.isMiniProgram" filterable clearable placeholder="操作来源" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="小程序" :value="true" />
        <el-option label="电脑端" :value="false" />
      </el-select>
      <el-select v-model="query.status" filterable clearable placeholder="状态" class="filter-item" style="width: 120px;" @change="toQuery">
        <el-option label="编辑中" :value="0" />
        <el-option label="已入库" :value="1" />
      </el-select>
      <el-input v-model.trim="query.code" clearable placeholder="输入入库单号进行搜索" class="filter-item" style="width: 180px" @keyup.enter.native="toQuery" />
      <el-button class="filter-item" size="mini" type="success" icon="el-icon-search" @click="toQuery">搜索</el-button>
      <div style="display: inline-block;margin: 0px 2px;">
        <el-button type="primary" icon="el-icon-plus" @click="add" class="filter-item">新增入库单</el-button>
        <!-- <el-dropdown split-button type="primary" class="filter-item" @click="add" @command="addByOrders">
          <i class="el-icon-plus"></i>&nbsp;新增入库单
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item command="consignment">采购入库：根据发货单入库</el-dropdown-item>
            <el-dropdown-item command="return">退货入库：根据退货单入库</el-dropdown-item>
            <el-dropdown-item command="allocation">调拨入库：根据调拨单入库</el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>-->
      </div>
      <el-button class="filter-item" size="mini" type="info" icon="el-icon-refresh-left" @click="toQuery">刷新</el-button>
    </div>

    <el-table v-loading="loading" row-key="id" :data="data" size="small" style="width: 100%;">
      <el-table-column prop="warehouseName" label="库房" min-width="120" />
      <el-table-column prop="formCode" label="单号" min-width="120" />
      <el-table-column label="入库类型" min-width="100">
        <template slot-scope="scope">
          <span>{{storageTypes[scope.row.formType]}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="formTime" label="入库日期" width="150" :formatter="r => {return new Date(r.formTime).format('yyyy/MM/dd');}" />
      <el-table-column prop="info" label="备注" min-width="240" show-overflow-tooltip />
      <el-table-column label="操作来源" prop="isMiniProgram" width="100" sortable="custom">
        <template slot-scope="scope">{{scope.row.isMiniProgram?"小程序":"电脑端"}}</template>
      </el-table-column>
      <el-table-column label="状态" width="110" align="center">
        <template slot-scope="scope">
          <el-tag :type="status[scope.row.status].type">{{status[scope.row.status].label}}</el-tag>
        </template>
      </el-table-column>

      <el-table-column width="100px" fixed="right">
        <div class="row-commands" slot-scope="scope">
          <el-button size="mini" type="text" @click="edit(scope.row)">{{scope.row.status === 0 ? "编辑" : "查看"}}</el-button>
          <el-popover :ref="scope.row.id" placement="top" width="180" v-if="scope.row.status === 0">
            <p>确定删除本条数据吗？</p>
            <div style="text-align: right; margin: 0">
              <el-button size="mini" type="text" @click="$refs[scope.row.id].doClose()">取消</el-button>
              <el-button :loading="delLoading" type="primary" size="mini" @click="subDelete(scope.row.id)">确定</el-button>
            </div>
            <el-button slot="reference" class="danger" type="text" size="mini">删除</el-button>
          </el-popover>
        </div>
      </el-table-column>
    </el-table>

    <el-pagination :total="total" :current-page="page + 1" style="margin-top: 8px;" layout="total, prev, pager, next, sizes" @size-change="sizeChange" @current-change="pageChange" />

    <eForm ref="form" />
    <reference-order ref="referenceOrder" @select="addByOrder" />
  </div>
</template>

<script>
import checkPermission from "@/utils/permission";
import initData from "@/mixins/initData";
import { edit, del } from "@/api/storageManage";
import eForm from "./form";
import referenceOrder from "./reference-order";

export default {
  components: { eForm, referenceOrder },
  mixins: [initData],
  data() {
    return {
      delLoading: false,
      defaultTime: ["00:00:00", "23:59:59"],
      storageTypes: {
        1: "采购入库",
        3: "退货入库",
        5: "调拨入库",
        0: "其他",
      },
      status: [
        { label: "编辑中", type: "info" },
        { label: "已入库", type: "success" },
      ],
      query: {
        storageId: null, //库房id
        formType: null,
        dateRange: null,
        status: null,
        code: null,
      },
    };
  },
  created() {
    this.init();
  },
  methods: {
    checkPermission,
    beforeInit() {
      this.url = "api/inoutForm";
      const sort = "formCode,desc";
      this.params = {
        page: this.page,
        size: this.size,
        inoutType: 0,
        sort: sort,
      };
      let query = JSON.parse(JSON.stringify(this.query));
      // 时间范围
      if (query.dateRange && query.dateRange.length === 2) {
        this.params["beginTime"] = query.dateRange[0];
        this.params["endTime"] = query.dateRange[1];
      }
      // 库房
      if (query.storageId) {
        this.params["warehouseId"] = query.storageId;
      }
      // 单号
      if (query.code) {
        this.params["formCode"] = query.code;
      }
      if (query.formTypeList) {
        this.params["formTypeList"] = query.formTypeList;
      }
      if (query.status != null) {
        this.params["status"] = query.status;
      }
      this.params["isMiniProgram"] = query.isMiniProgram;

      return true;
    },

    subDelete(id) {
      this.delLoading = true;
      del(id)
        .then((res) => {
          this.delLoading = false;
          this.$refs[id].doClose();
          this.dleChangePage();
          this.init();
          this.$notify({
            title: "删除成功",
            type: "success",
            duration: 2500,
          });
        })
        .catch((err) => {
          this.delLoading = false;
          this.$refs[id].doClose();
        });
    },
    addByOrders(orderType) {
      this.$refs.referenceOrder && this.$refs.referenceOrder.show(orderType);
    },
    add() {
      this.$refs.form && this.$refs.form.resetForm();
    },
    addByOrder(type, order) {
      this.$refs.form && this.$refs.form.resetForm(null, type, order);
    },
    edit(data) {
      this.$refs.form && this.$refs.form.resetForm(data);
    },
  },
};
</script>